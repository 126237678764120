body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* color: #054569 */

:root {
  --primaryColor: #054569;
  --secondaryColor: #005eec;
}
::-webkit-scrollbar {
  width: 10px;
  background: #f5f5f5;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #222;
}
.planDetails::-webkit-scrollbar {
  display: none;
}

.banner-image {
  max-height: 200px;
  max-width: 400px;
}

.banner-image:hover {
  cursor: pointer;
}
