body {
  font-family: "Roboto", sans-serif;
  line-height: 30px;
}

/* Top navbar */
#header {
  background: #054569;
}
.header {
  color: #fff;
}

header img {
  height: 100px;
}
.container {
  max-width: 1260px;
  margin: auto;
}

.d-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.privacypolicy_content p,
.privacypolicy_content ul {
  font-size: 18px;
  font-weight: 300;
}
h1 {
  font-size: 32px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}

.note {
  background: #05456922;
}

#footer {
  background: #222;
  color: #fff;
}
.text-center {
  margin: auto;
  text-align: center;
}
.text-decoration-none {
  text-decoration: none;
  color: dodgerblue;
}
